import React from "react";

import Helmet from "react-helmet";

import Header from "@components/header";
import Footer from "@components/footer";

import { Collapse, Row, Col } from "antd";

import pcImg from "@src/images/question/Rectangle4387.png";
import phoneImg from "@src/images/question/Rectangle4386.png";

import TextCenterBanner from "@components/textCenterBanner";

import * as styles from "./index.module.scss";

const questionDatas = [
  {
    question: "如何操作升级套餐？",
    answer:
      "我们支持采用补差价的方式升级套餐。 如果您有升级的需求，可以登陆商家后台，点击右上角头像，点击“订阅管理”-“本期订阅”-“操作”，选择“升级套餐”进行升级。 您需要支付的费用为新套餐的价格减去基于老版本套餐剩余时间折算后的价格。",
  },
  {
    question: "Shoprise是否支持绑定自己的域名？",
    answer:
      "支持。如果您已经购买了相关域名，您可以直接将购买的域名绑定到Shoprise店铺。在网站管理后台首页的“个性化独立站域名”模块即可完成域名绑定。 同时，针对每一个商店，Shoprise的后台都会自动生成一个域名，供您免费使用。",
  },
  {
    question: "注册店铺后不会操作该怎么办？",
    answer:
      "Shoprise的后台操作十分简单，按照我们的提示，您可以在 30 分钟内轻松完成网站搭建。并且在您购买套餐后，我们将为您提供“小白无忧“1对1的专业服务来确保您正确快捷地上手业务。",
  },
  {
    question: "Shoprise所提供的模板需要收费吗？",
    answer:
      "不需要，使用Shoprise后台提供的任何模版，您都无需额外支付费用。Shoprise团队会定期更新行业模版，您可以根据您的需求选择最适合的模版设计。",
  },
  {
    question: "我需要为所使用的带宽付费吗？",
    answer:
      "不需要，使用Shoprise，您无需额外支付带宽费用。所有套餐都支持无限带宽使用。",
  },
  {
    question: "创建独立站要收费吗？",
    answer:
      "免费，创建独立站是我们的免费服务，我们的收费服务是协助您进行店铺运营管理以及将您的生意推广至全球。",
  },
  {
    question: "我要输入信用卡信息才能注册账户吗？",
    answer:
      "不需要。新用户有30天免费试用Shoprise的机会，且无需输入信用卡信息。试用期结束后，如果您想正式开店营业，则需要选择一个每月维护方案，再输入相关信用卡信息。",
  },
  {
    question: "我可以随时注销账号吗？",
    answer:
      "当然可以。如果您认为不再需要使用当前的服务，可以随时注销账号，这不会给您带来任何的后顾之忧。",
  },
  {
    question: "你们支付方案的合同有效期是多久？",
    answer: "所有Shoprise价格方案均按月计费。",
  },
  {
    question: "Shoprise是否 PCI 合规或拥有 PCI 认证？",
    answer:
      "是的，Shoprise拥有支付卡行业数据安全一级标准 (PCI DSS) 认证。这意味着您所有的数据和客户信息在我们这里都将完全保密。",
  },
  {
    question: "我必须在实体店和独立站销售一样的产品吗？",
    answer:
      "不用，您可以将独立站理解为您的另一个店铺，它们可以卖相同的产品，也可以卖不同的产品，只要您愿意，您可以自行决定在实体店和独立站销售的产品。",
  },
  {
    question: "交易产生任何手续费吗？",
    answer:
      "如果您选择使用外部支付网关，Shoprise Essentials用户需额外支付2%交易费，Shoprise Standard用户支付1%交易费，Shoprise Premium用户的交易费需要通过咨询议价。 ",
  },
  {
    question: "我可以在 Shoprise使用第三方支付平台吗？",
    answer:
      "可以，Shoprise支持使用多种第三方支付平台，以此确保您的生意能够平稳进行。 ",
  },
  {
    question: "我需要自己购买服务器吗？",
    answer:
      "Shoprise的所有价格方案都已包含由全球顶尖的电商服务器提供的服务，我们将通过网络来为您提供相应的业务赋能，您只需要使用这种服务即可，不需要额外添加任何的设备。 ",
  },
];

const QuestionPage = () => {
  return (
    <div className={styles.questionPage}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>常见问题-Shoprise 官网</title>
      </Helmet>
      <Header />
      <TextCenterBanner
        pcImg={pcImg}
        phoneImg={phoneImg}
        text={"Shoprise  常见问题"}
      />
      <Row>
        <Col xs={0} sm={0} md={24}>
          <div className={styles.content}>
            {questionDatas.map((item) => {
              return (
                <div className={styles.questionContainer} key={item.question}>
                  <div className={styles.question}>{item.question}</div>
                  <div className={styles.answer}>{item.answer}</div>
                </div>
              );
            })}
          </div>
        </Col>
        <Col xs={24} sm={24} md={0}>
          <div className={styles.smallContent}>
            <Collapse
              defaultActiveKey={[0]}
              expandIconPosition="right"
              ghost
              bordered="false"
            >
              {questionDatas.map((item) => (
                <Collapse.Panel header={item.question} key={item.question}>
                  {item.answer}
                </Collapse.Panel>
              ))}
            </Collapse>
          </div>
        </Col>
      </Row>

      <Footer />
    </div>
  );
};

export default QuestionPage;

//其他页面文字位于banner 中央的banner 组件

//需要传入三个参数，pcImg，phoneImg,text ,分别对应的是 pc端的图片地址，移动端的图片地址，和需要显示的文字
import React from "react";
import * as styles from "./index.module.scss";


const TextCenterBanner = ({ pcImg, phoneImg, text }) => {
  return (
    <div className={styles.textCenterBanner}>
      <picture>
        <source media="(min-width: 960px)" srcSet={pcImg} />
        <source media="(max-width: 960px)" srcSet={phoneImg} />
        <img src={pcImg} alt={pcImg} style={{ width: "100%" }} />
      </picture>
      <div className={styles.text}>{text}</div>
    </div>
  );
};
export default TextCenterBanner;
